import { createWebAPIClient } from '../../base/web/api.js';

const call = createWebAPIClient();

// DEFINITIONS -- autogenerated below this line, to refresh run ./script.sh generateAPI

export type LogOutParams = {};
export const logOut = (params: LogOutParams) => call('logOut', params) as Promise<any>;

export type RequestRestoreEmailParams = { readonly email: string };
export type RequestRestoreEmailResult = { error?: string; success?: boolean };
export const requestRestoreEmail = (params: RequestRestoreEmailParams) => call('requestRestoreEmail', params) as Promise<RequestRestoreEmailResult>;

export type SaveGameScreenShotParams = { readonly game: string; readonly screen: (File | Blob) };
export type SaveGameScreenShotResult = { error?: string; success?: boolean; path?: string };
export const saveGameScreenShot = (params: SaveGameScreenShotParams) => call('saveGameScreenShot', params) as Promise<SaveGameScreenShotResult>;

export type SetPasswordWithTokenParams = { readonly token: string; readonly password: string };
export type SetPasswordWithTokenResult = { error?: string; success?: boolean; redir?: string };
export const setPasswordWithToken = (params: SetPasswordWithTokenParams) => call('setPasswordWithToken', params) as Promise<SetPasswordWithTokenResult>;

export type SignInParams = { readonly email: string; readonly password: string };
export type SignInResult = { error?: string; success?: boolean; redir?: string };
export const signIn = (params: SignInParams) => call('signIn', params) as Promise<SignInResult>;

export type SignUpParams = { readonly email: string; readonly password: string };
export type SignUpResult = { error?: string; success?: boolean; redir?: string };
export const signUp = (params: SignUpParams) => call('signUp', params) as Promise<SignUpResult>;

export type StartSocialAuthParams = { readonly method: string };
export type StartSocialAuthResult = { error?: string; redir?: string };
export const startSocialAuth = (params: StartSocialAuthParams) => call('startSocialAuth', params) as Promise<StartSocialAuthResult>;

export type UpdateUserParams = { email: string } & { password?: null | string };
export type UpdateUserResult = { error?: string; success?: boolean };
export const updateUser = (params: UpdateUserParams) => call('updateUser', params) as Promise<UpdateUserResult>;

