import { Component } from 'preact';
import { requestRestoreEmail, setPasswordWithToken, signIn, signUp, startSocialAuth } from '../lib/api.js';
import { get } from 'dtk';
import { digestPassword } from '../lib/util.js';
import { Page } from './page.js';
import { style } from 'typestyle';
import { em, px } from 'csx';
import { FacebookIcon, GoogleIcon, TwitterIcon } from './icons.js';
import { shadowRounded } from '../styles.js';
import { queue } from '../../base/common/async.js';

export function SocialAuth() {

	const socialAuthProviders: [typeof GoogleIcon, string][] = [
		[GoogleIcon, 'google'],
		[FacebookIcon, 'facebook'],
		[TwitterIcon, 'twitter'],
	];
	
	async function start(method: string) {
		const after = (location.pathname.match(/^\/auth\//)) ? '/' : location.href;
		document.cookie = `authRedir=${encodeURIComponent(after)}; path=/`;
		const res = await startSocialAuth({ method });
		if (res.redir) location.href = res.redir;
	}

	return <div class={style({
		width: px(200),
		display: 'flex',
		flexDirection: 'column',
		margin: '0 auto',
		padding: em(.3),
	})}>
		{socialAuthProviders.map(([Icon, provider]) => <div data-method={provider} onClick={() => start(provider)} class={style({
			padding: em(.3),
			margin: em(.3),
			border: '2px solid #408FC0',
			borderRadius: px(10),
		})}>
			<Icon class={style({ height: px(40) })}/>
			<span class={style({ textTransform: 'capitalize' })}>{provider}</span>
		</div>)}
	</div>;
	
}

const wrapSubmit = (form: any) => (e: Event) => {
	e.preventDefault();
	form.submit(e.target as HTMLFormElement);
};

export interface AuthFormProps {
	switchForm?: (comp: Function) => void;
	noTitle?: boolean;
}

interface AuthFormState {
	error?: string;
	success?: string;
}

const formSwitcher = (props: AuthFormProps, comp: Function) => {
	if (props.switchForm) return (e: Event) => {
		e.preventDefault();
		props.switchForm!(comp);
	};
};

const pageStyle = {
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
} as const;

const formStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-end',
	paddingRight: em(3),
	lineHeight: 2.4,
} as const;

export class SignInForm extends Component<AuthFormProps, AuthFormState> {

	render(props: AuthFormProps, state: AuthFormState) {
		return <Page class={style(pageStyle)}>
			<h1>Sign in</h1>
			<div class={style(shadowRounded)}>
				<SocialAuth />
				<form onSubmit={wrapSubmit(this)} class={style(formStyle)}>
					<label>
						<span>Email address:</span>
						<input type="text" name="email" placeholder="you@example.org" autocomplete="email" />
					</label>
					<label>
						<span>Password:</span>
						<input type="password" name="password" placeholder="password" autocomplete="current-password" />
					</label>
					{state.error && <div>{state.error}</div>}
					<button type="submit">Sign in</button>
					<div>
						<a href="/auth/sign-up" onClick={formSwitcher(props, SignUpForm)}>Sign up for a new account</a>
						<a href="/auth/restore" onClick={formSwitcher(props, RestoreForm)}>Restore password</a>
					</div>
				</form>
			</div>
		</Page>;
	}
	
	submit = queue(async (form: HTMLFormElement) => {
		
		this.setState({ error: undefined, success: undefined });

		const email = get(form, 'input', '[name=email]')!.value.trim();
		const pwtext = get(form, 'input', '[name=password]')!.value.trim();
		if (!email || !pwtext) return this.setState({ error: 'Please fill in all fields' });

		const password = await digestPassword(pwtext);

		const res = await signIn({ email, password });

		if (res.error || !res.success) this.setState({ error: res.error || 'Unknown error' });

		if (res.success) {
			if (location.pathname.match(/^\/auth\//)) location.href = '/';
			else location.reload();
			return queue.done;
		}

	});
	
}

export class SignUpForm extends Component<AuthFormProps, AuthFormState> {

	render(props: AuthFormProps, state: AuthFormState) {
		return <Page class={style(pageStyle)}>
			{!props.noTitle && <h1>Sign up</h1>}
			<div class={style(shadowRounded)}>
				<SocialAuth />
				<form onSubmit={wrapSubmit(this)} class={style(formStyle)}>
					<label>
						<span>Email address:</span>
						<input type="text" name="email" placeholder="you@example.org" autocomplete="email" />
					</label>
					<label>
						<span>Password:</span>
						<input type="password" name="password" placeholder="password" autocomplete="new-password" />
					</label>
					<label>
						<span>Confirm password:</span>
						<input type="password" name="confirm" placeholder="confirm" autocomplete="new-password" />
					</label>
					{state.error && <div>{state.error}</div>}
					<button type="submit">Sign up</button>
					<div>
						<a href="/auth" onClick={formSwitcher(props, SignInForm)}>Sign in with existing account</a>
						<a href="/auth/restore"onClick={formSwitcher(props, RestoreForm)}>Restore password</a>
					</div>
				</form>
			</div>
		</Page>;
	}

	submit = queue(async (form: HTMLFormElement) => {

		this.setState({ error: undefined, success: undefined });

		const email = get(form, 'input', '[name=email]')!.value.trim();
		const pwtext = get(form, 'input', '[name=password]')!.value.trim();
		const confirm = get(form, 'input', '[name=confirm]')!.value.trim();
		if (!email || !pwtext) return this.setState({ error: 'Please fill in all fields' });
		if (pwtext != confirm) return this.setState({ error: 'Passwords do not match' });

		const password = await digestPassword(pwtext);

		const res = await signUp({ email, password });

		if (res.error || !res.success) this.setState({ error: res.error || 'Unknown error' });
		
		if (res.success) {
			if (location.pathname.match(/^\/auth\//)) location.href = '/';
			else location.reload();
			return queue.done;
		}
		
	});

}

export class RestoreForm extends Component<AuthFormProps, AuthFormState> {

	render(props: AuthFormProps, state: AuthFormState) {
		return <Page>
			<form onSubmit={wrapSubmit(this)}>
				<h1>Restore password</h1>
				<label>
					<span>Email address:</span>
					<input type="text" name="email" placeholder="you@example.org" autocomplete="email" />
				</label>
				{state.error && <div>{state.error}</div>}
				{state.success && <div>{state.success}</div>}
				<button type="submit">Get link</button>
				<div>
					<a href="/auth" onClick={formSwitcher(props, SignInForm)}>Sign in</a>
					<a href="/auth/sign-up" onClick={formSwitcher(props, SignUpForm)}>Sign up</a>
				</div>
			</form>
		</Page>;
	}

	submit = queue(async (form: HTMLFormElement) => {

		this.setState({ error: undefined, success: undefined });

		const email = get(form, 'input', '[name=email]')!.value.trim();
		if (!email) return this.setState({ error: 'Please fill in the email' });

		const res = await requestRestoreEmail({ email });

		if (res.error || !res.success) this.setState({ error: res.error || 'Unknown error' });
		if (res.success) this.setState({ success: 'Please see your inbox for further instructions.' });

	});

}

interface SetPasswordProps {
	token: string;
}

export class SetPasswordForm extends Component<SetPasswordProps, AuthFormState> {

	render(props: SetPasswordProps, state: AuthFormState) {
		
		return <Page>
			<form onSubmit={wrapSubmit(this)}>
				<h1>Set new password</h1>
				Token: {props.token}
				<label>
					<span>Password:</span>
					<input type="password" name="password" placeholder="password" autocomplete="new-password" />
				</label>
				<label>
					<span>Confirm password:</span>
					<input type="password" name="confirm" placeholder="confirm" autocomplete="new-password" />
				</label>
				{state.error && <div>{state.error}</div>}
				<button type="submit">Set password</button>
				<div>
					<a href="/auth">Sign in</a>
					<a href="/auth/sign-up">Sign up</a>
				</div>
			</form>
		</Page>;

	}

	submit = queue(async (form: HTMLFormElement) => {

		this.setState({ error: undefined, success: undefined });

		const pwtext = get(form, 'input', '[name=password]')!.value.trim();
		const confirm = get(form, 'input', '[name=confirm]')!.value.trim();
		if (!pwtext || !confirm) return this.setState({ error: 'Please fill in all fields' });
		if (pwtext != confirm) return this.setState({ error: 'Passwords do not match' });

		const password = await digestPassword(pwtext);

		const res = await setPasswordWithToken({ password, token: this.props.token });

		if (res.error || !res.success) this.setState({ error: res.error || 'Unknown error' });
		if (res.success) {
			location.href = '/';
			return queue.done;
		}
		
	});
	
}
