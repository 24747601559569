import { ComponentFactory, h, render } from 'preact';
import { RestoreForm, SetPasswordForm, SignInForm, SignUpForm } from './auth.js';
import { GamePage } from './game.js';
import { GameFrame } from './game-frame.js';
import { AllGamesPage } from './all-games.js';
import { SettingsEditor } from './settings.js';
import { ContentPage } from './content.js';
import { ClientInit } from '../../common/types.js';
import { ScreenshotMaker } from './screenshot-maker.js';
import { ContentComparer } from './content-comparer.js';

export const components: Record<string, ComponentFactory<any>> = {

	ContentPage,
	
	SignInForm,
	SignUpForm,
	RestoreForm,
	SetPasswordForm,
	SettingsEditor,
	
	AllGamesPage,
	GamePage,
	GameFrame,
	
	// TODO remove at launch
	ScreenshotMaker,
	ContentComparer,
	
};

export function initComponent(init: ClientInit) {
	
	if (!init.component) return;
	const cls = components[init.component];
	
	const it = document.querySelector(init.selector ?? '#page');
	if (!it) throw new Error(`Component root not found for ${init.component}`);
	
	render(h(cls, init.props), it.parentElement!, it);
	
}
