import { ComponentChildren, JSX } from 'preact';

export function Page({ children, ...attrs }: JSX.HTMLAttributes<HTMLDivElement> & {
	children: ComponentChildren;
}) {
	return <div id="page" {...attrs}>
		{children}
	</div>;
}

