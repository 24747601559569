import { classes, style } from 'typestyle';
import { em, px } from 'csx';
import { fontFredoka } from '../styles.js';
import { DeskIcon, FacebookIcon, PinterestIcon, TwitterIcon } from './icons.js';

export interface ShareProps {
	pathname: string;
	shareStyle?: string;
}

export function Share({ pathname, shareStyle }: ShareProps ) {

	const url: string = 'https://' + (typeof location == 'object' ? location.hostname : 'angles365.com') + pathname;
	
	const description: string = encodeURIComponent('angles365.com — lots of educational materials for students and tutors');
	
	const shareIcons: [typeof DeskIcon, string, string][] = [
		[PinterestIcon, 'Share on Pinterest', `http://pinterest.com/pin/create/button/?url=${url}&description=${description}`],
		// [WhatsappIcon, "Share on Whatsapp", "#"],
		[FacebookIcon, 'Share on Facebook', `http://www.facebook.com/sharer/sharer.php?u=${url}&t=${description}`],
		[TwitterIcon, 'Share on Twitter', `http://twitter.com/share?text=${description}&url=${url}`],
		// [InstagramIcon, "Share on Instagram", "#"]
	];

	return (
		<div class={classes(shareStyle, style({
			display: 'flex',
			alignItems: 'center',
		}))}>
			<div class={style(fontFredoka, { fontSize: px(36) })}>Share</div>
			<div class={style({
				backgroundColor: 'white',
				borderRadius: px(8),
				padding: px(4),
				marginLeft: em(.6),
			})}>
				{shareIcons.map(([Icon, alt, url]) => <a href={url}>
					<Icon class={style({ height: px(24), color: '#408FC0', margin: px(4) })} alt={alt}/>
				</a>)}
			</div>
		</div>

	);
}
