import { Logo } from './icons.js';
import { classes, media, style } from 'typestyle';
import {
	backgroundColor,
	centered,
	contentPadding,
	fontFredoka,
	mediaBreakpointLandscapeOrTablet,
	mobileHeaderColor,
	mobileHeaderHeight,
	notchMarginLeftRight,
	notchPaddingLeftRight,
	shadow,
} from '../styles.js';
import { percent, px } from 'csx';
import { useState } from 'preact/hooks';
import { NestedCSSProperties } from 'typestyle/lib/types.js';
import { getThumb } from '../../common/util.js';
import { MenuCache } from '../types.js';
import { apply } from '../../base/common/scope.js';
import { Language } from '../../common/data.js';

export interface HeaderProps {
	menuCache?: MenuCache;
	gamePage?: boolean;
	languages: Language[];
}

export function Header({ menuCache, gamePage, languages }: HeaderProps) {
	
	const [open, setOpen] = useState(false);
	
	const burgerSlice : NestedCSSProperties = {
		width: px(38),
		height: px(7),
		borderRadius: px(5),
		backgroundColor: 'white',
		margin: '6px 0',
		transition: 'background-color 0.4s ease',
	};
	
	const showLangs = languages.filter(lang => lang.visible && lang.flag);
	
	return <div id="header">
		<div class={style(
			media({ minWidth: mediaBreakpointLandscapeOrTablet }, { display: 'flex' }),
			media({ minWidth: 0, maxWidth: mediaBreakpointLandscapeOrTablet-1 }, { display: 'none' }),
			centered,
			{
				padding: `20px ${contentPadding}px 60px`,
				flexDirection: 'column',
				$debugName: 'header_container',
			}
		)}>
			{showLangs.length > 1 && <LanguageBar class={style({ alignSelf: 'flex-end' })} languages={showLangs} />}
			<div class={style({
				marginTop: px(10),
				display: 'flex',
				justifyContent: 'space-between',
				$debugName: 'header_main',
			})}>
				<a href="/">
					<Logo class={style(
						shadow,
						{
							width: px(143),
							$debugName: 'Logo',
						}
					)}/>
				</a>
				{menuCache!.menus.header.entries.map(me => apply(me.image && menuCache!.svgs[me.image], svg => <div class={style({
					display: 'flex',
					flexDirection: 'column',
					paddingTop: px(8),
					$debugName: 'menu_map',
				})}>
					<a href={me.link} class={`${style({
						textAlign: 'center',
						$nest: Object.fromEntries(['svg', 'img'].map(tag => [tag, {
							...shadow,
							height: px(60),
						}])),
					})} menu-${svg ? 'svg' : 'img'}-container`} data-svg={me.image} dangerouslySetInnerHTML={svg && { __html: svg } || undefined}>
						{!svg && me.image && <img src={getThumb(`/data/menus/${me.image}`, 250, 'png')} />}
					</a>
					<a href={me.link} class={style(
						fontFredoka, {
							marginTop: px(8),
							textDecoration: 'none',
							fontSize: px(22),
						}
					)}>
						{me.title}
					</a>
				</div>))}
			</div>
		</div>
		<div key="mobileContainer" class={style(
			
			media({ minWidth: 0, maxWidth: mediaBreakpointLandscapeOrTablet-1 },
				
				notchPaddingLeftRight('0px'),
				{
					display: 'flex',
					$debugName: 'mobileContainer_media',
				}),
			media({ minWidth: mediaBreakpointLandscapeOrTablet }),
			{ display: 'none' },
			{
				$debugName: 'mobileContainer_desktop',
				height: px(mobileHeaderHeight),
				backgroundColor: mobileHeaderColor,
				borderBottom: '2px solid white',
				alignItems: 'center',
				position: 'fixed',
				width: percent(100),
				zIndex: 1000,
				top: 0,
				justifyContent: 'space-between',
			},
			gamePage && media({ orientation: 'landscape', maxWidth: mediaBreakpointLandscapeOrTablet-1 }, { display: 'none' }),
		)}>
			<div onClick={() => setOpen(!open)} class={style({ margin: '0 12px' })}>
				<div class={classes(style(burgerSlice), open && style({ backgroundColor: '#FC0228' }))}/>
				<div class={classes(style(burgerSlice), open && style({ backgroundColor: '#FF7802' }))}/>
				<div class={classes(style(burgerSlice), open && style({ backgroundColor: '#019A48' }))}/>
			</div>
			<div class={style(
				fontFredoka,
				{
					position: 'absolute',
					left: percent(50),
					transform: 'translateX(-50%)',
					fontSize: px(28),
					width: 'fit-content',
				}
			)}>
				<a href="/" class={style({ textDecoration: 'none' })}>ANGLÈS 365</a>
			</div>
			{showLangs.length > 1 && <LanguageBar class={style({ marginRight: px(8) })} languages={showLangs} />}
		</div>
		
		{gamePage && <div onClick={() => setOpen(!open)} class={style(
			notchMarginLeftRight('10px'),
			{
				$debugName: 'floating_burger',
				display: 'none',
				position: 'fixed',
				top: px(10),
				left: px(0),
				zIndex: 110,
				backgroundColor: backgroundColor,
				borderRadius: px(8),
				padding: '1px 4px',
			},
			media({ orientation: 'landscape', maxWidth: mediaBreakpointLandscapeOrTablet }, { display: 'block' })
		)}>
			<div class={classes(style(burgerSlice), open && style({ backgroundColor: '#FC0228' }))}/>
			<div class={classes(style(burgerSlice), open && style({ backgroundColor: '#FF7802' }))}/>
			<div class={classes(style(burgerSlice), open && style({ backgroundColor: '#019A48' }))}/>
		</div>}
		
		{open && <div class={style(
			media({ minWidth: 0, maxWidth: mediaBreakpointLandscapeOrTablet-1 },
				notchPaddingLeftRight('0px'),
				{
					$debugName: 'dropdown_menu',
					paddingTop: px(mobileHeaderHeight),
					display: 'block',
					position: 'fixed',
					backgroundColor: backgroundColor,
					zIndex: 100,
					width: percent(100),
					maxHeight: 'calc(90vh - ' + mobileHeaderHeight + 'px)',
					overflowX: 'scroll',
					filter: 'drop-shadow(0px 10px 0px rgba(0,0,0,0.45))',
					transform: 'translateZ(0)',
					top: px(0),
				}),
			media({ minWidth: mediaBreakpointLandscapeOrTablet }),
			{ display: 'none' },
			{
				$debugName: 'dropdown_menu_hidden',
				borderBottom: '2px solid white',
			}
		)}>
			{menuCache!.menus.header.entries.map(me => apply(me.image && menuCache!.svgs[me.image], svg => <div class={style({
				$debugName: 'dropdown_menu_item',
				display: 'flex',
				alignItems: 'center',
			}, gamePage && media({ orientation: 'landscape' }, { paddingLeft: px(40) }))}>
				<a href={me.link} class={`${style({
					textAlign: 'center',
					$nest: Object.fromEntries(['svg', 'img'].map(tag => [tag, {
						...shadow,
						height: px(40),
						width: px(40),
						margin: '15px 20px',
					}])),
				})} menu-${svg ? 'svg' : 'img'}-container`} data-svg={me.image} dangerouslySetInnerHTML={svg && { __html: svg } || undefined}>
					{!svg && me.image && <img src={getThumb(`/data/menus/${me.image}`, 250, 'png')} />}
				</a>
				<a href={me.link} class={style(
					fontFredoka, {
						textDecoration: 'none',
						fontSize: px(22),
					}
				)}>
					{me.title}
				</a>
			</div>))}
		</div>}
	</div>;
}

function LanguageBar({ languages, class: className }: { languages: Language[], class: string }) {
	return <div
		className={classes(className, style({
			display: 'flex',
			justifyContent: 'flex-end',
			gap: px(8),
			background: 'white',
			borderRadius: px(4),
			padding: px(6),
		}))}
	>
		{languages.map(lang => <a
			className={style({
				display: 'block',
			})}
			href={`https://${lang.domain}`}
			title={lang.name}
		>
			<img
				className={style({
					display: 'block',
					height: px(24),
				})}
				alt={lang.name}
				src={`/data/flags/${lang.flag}`}
			/>
		</a>)}
	</div>;
}
