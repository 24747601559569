import { Component } from 'preact';
import { style } from 'typestyle';
import { px, viewHeight, viewWidth } from 'csx';
import { Game } from '../../common/data.js';
import { Page } from './page.js';
import { backgroundColor } from '../styles.js';
import { ExitFullscreenIcon } from './icons.js';

export interface GamePageProps {
	game: Game;
	localPath?: string;
	ruffleVersion: string | number;
}
interface GamePageState {
}

export class GameFrame extends Component<GamePageProps, GamePageState> {
	container: HTMLElement | null = null;
	
	constructor(props: GamePageProps) {
		super(props);
	}
	
	render(props: GamePageProps, state: GamePageState) {
		return <Page class={style({
			margin: 0,
			background: 'white',
			position: 'relative',
		})}>
			<div ref={it => this.container = it} class={style({
				$nest: {
					'ruffle-player': {
						width: viewWidth(100),
						height: viewHeight(100),
					},
				},
			})} />
			<button
				class={style({
					position: 'absolute',
					top: 0,
					right: 0,
					border: 0,
					borderBottomLeftRadius: px(8),
					backgroundColor: backgroundColor,
					cursor: 'pointer',
					display: 'none',
					$nest: {
						'@media all and (display-mode: fullscreen)': {
							display: 'block'
						}
					}
				})}
				onClick={() => document.exitFullscreen()}
			>
				<ExitFullscreenIcon class={style({ height: px(24), color: 'white', margin: px(4) })} />
			</button>
			<script src={`/ruffle/ruffle!${props.ruffleVersion}.js`} />
		</Page>;
	}

	
	async componentDidMount() {
	
		const ruffle = (window as any).RufflePlayer.newest();
		const player = ruffle.createPlayer();
		player.config.warnOnUnsupportedContent = false;
		this.container!.appendChild(player);
		await player.load(this.props.game.path);
		
		window.addEventListener('message', e => {
			if (e.data == 'enterFullscreen') {
				document.body.requestFullscreen({ navigationUI: 'hide' });
			}
		});
		
	}
	
}



