import { Game } from '../../common/data.js';
import { Page } from './page.js';
import { style } from 'typestyle';
import { centered } from '../styles.js';
import { percent, px } from 'csx';
import { getThumb } from '../../common/util.js';

export function AllGamesPage({ games }: { games: Game[] }) {
	return <Page>
		<table class={style(
			centered,
			{
				$nest: {
					td: {
						border: '1px solid white',
					},
				},
			}
		)}>
			{games.map(game => <tr>
				<td class={style(
					game.test_status !== null ? { background: game.test_status ? 'green' : 'red' } : {},
					{ width: px(50), textAlign: 'center' }
				)} title={game.test_comment}>
					{game.test_status !== null ? (game.test_status ? 'good' : 'bad') : ''}
				</td>
				<td class={style({ width: px(100) })}>{game.screenshot && <img alt="screen" class={style({ width: percent(100) })} src={getThumb(`data/screenshots/${game.id}/${game.screenshot}`)} />}</td>
				<td>
					<a href={`/game/${game.id}`}>{game.path}</a>
				</td>
				<td>{game.aliases.map(a => <div><a href={a}>{a}</a></div>)}</td>
			</tr>)}
		</table>
	</Page>;
}
