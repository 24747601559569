import { Component } from 'preact';
import { Game } from '../../common/data.js';
import { px } from 'csx';
import { Page } from './page.js';
import { saveGameScreenShot } from '../lib/api.js';
import { expect } from '../../base/common/scope.js';

export interface ScreenshotMakerProps {
	games: Game[];
}

interface ScreenshotMakerState {
	game?: Game;
}

export class ScreenshotMaker extends Component<ScreenshotMakerProps, ScreenshotMakerState> {
	
	frame: HTMLIFrameElement | null = null;

	render(props: ScreenshotMakerProps, { game }: ScreenshotMakerState) {
		return <Page>
			{game && <iframe
				ref={it => this.frame = it}
				src={`/game-frame/${game.id}${game.path}.html#force-start`}
				style={{ width: px(1000), height: px(Math.round(1000 / (game.aspect_ratio || (4/3)))) }}
				scrolling="no"
			/>}
			<pre>{JSON.stringify(game, null, '\t')}</pre>
		</Page>;
	}
	
	async componentDidMount() {
	
		const sleep = (t: number) => new Promise(resolve => setTimeout(resolve, t));
	
		for (const game of this.props.games) {
		
			try {
				
				this.setState({ game });
				await sleep(5000);
				
				const canvas = (this.frame!.contentDocument!.querySelector('ruffle-player') as any).shadow.querySelector('canvas') as HTMLCanvasElement;
				
				let blob: Blob;
				let lastSize = 0;
				let sum = 0;
				let samples = 0;
				
				while (true) {
				
					await sleep(1000);
					
					const sizeAvg = samples && sum / samples;
					
					blob = expect(await new Promise<Blob | null>(resolve => canvas.toBlob(resolve)));
					const cur = blob.size;
					const diff = Math.abs((cur - sizeAvg) / sizeAvg);
					console.log(cur, sizeAvg, diff);
					
					if (cur == lastSize) break;
					
					if (samples > 3 && sizeAvg > 5 * 1024 && diff < 0.1) break;
					
					if (samples >= 10) break;
					
					lastSize = cur;
					sum += cur;
					samples++;
					
				}
				
				const res = await saveGameScreenShot({
					game: game.id,
					screen: blob!,
				});
				
				if (res.error) console.error(res.error);
				if (res.success) console.log(game.id, res.path, blob!.size);
				
			}
			catch (e) {
				console.error(e);
				await sleep(500);
				location.reload();
			}
			
			const player = this.frame!.contentDocument!.querySelector('ruffle-player') as any;
			const instance = player?.instance;
			if (instance) try {
				instance.pause();
				await sleep(250);
				instance.destroy();
				player.instance = null;
				await sleep(250);
			}
			catch (e) {
				console.error(e);
			}
			
			this.setState({ game: undefined });
			await sleep(500);
			
		}
	}
	
}
