import { classes, media, style } from 'typestyle';
import { percent, px, url } from 'csx';
import {
	dropFirstLastMargin,
	fontFredoka,
	fontRoboto,
	mediaBreakpointLandscapeOrTablet,
	shadowRounded,
} from '../styles.js';

export interface CardProps {
	title?: string;
	image?: string;
	link?: string;
	description?: string;
	class?: string;
	blur?: boolean;
}

const hrefStyle = () => style({ textDecoration: 'none' });

const horizontalCardOuter = () => style(
	shadowRounded,
	{
		height: px(100),
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '10px 10px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		overflow: 'hidden',
	}
);

const horizontalImageContainer = () => style({
	position: 'relative',
	width: px(134),
	minWidth: px(134),
	height: percent(100),
	borderRadius: '0 10px 10px 0',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	margin: '0 14px 0 0',
	overflow: 'hidden',
	$nest: {
		'&::after': {
			paddingBottom: percent(75),
			content: '',
			display: 'block',
		},
	},
});

const horizontalCardImage = () => style({
	position: 'absolute',
	left: 0,
	top: 0,
	width: percent(100),
	height: percent(100),
	objectFit: 'contain',
	zIndex: 1,
});

const horizontalCardBackdrop = () => style({
	width: percent(100),
	filter: 'blur(10px)',
	zIndex: 0,
});

const horizontalCardTextOuter = () => style({
	height: percent(100),
	// width: percent(64),
	width: 'auto',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	textAlign: 'left',
});

const horizontalCardTitle = () => style(
	fontFredoka,
	{
		margin: '0 0 6px',
		fontSize: px(26),
		fontWeight: 400,
		lineHeight: px(31),
	}
);

const horizontalCardDescription = () => style(
	fontRoboto,
	{
		margin: 0,
		fontSize: px(14),
		lineHeight: px(16),
		$nest: dropFirstLastMargin(),
	}
);

export function HorizontalCard({ title, image, link, description, class: cls, blur }: CardProps) {
	
	return <div class={cls}>
		<a href={link} class={hrefStyle()}>
			<div class={horizontalCardOuter()}>
				<div class={classes(horizontalImageContainer(), image ? style({ backgroundColor: '#fff' }) : style({ backgroundColor: '#ccc' }))}>
					<img src={image} class={horizontalCardImage()} />
					{(blur !== false) && <img src={image} class={horizontalCardBackdrop()}/>}
				</div>
				<div class={horizontalCardTextOuter()}>
					<h2 class={horizontalCardTitle()}>
						{title}
					</h2>
					<div class={horizontalCardDescription()} dangerouslySetInnerHTML={{ __html: description || '' }} />
				</div>
			</div>
		</a>
	</div>;
}

const verticalCardOuter = () => style(
	shadowRounded,
	{
		width: percent(100),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflow: 'hidden',
	}
);

const verticalCardContainer = () => style({
	width: percent(100),
	height: 0,
	paddingTop: percent(75),
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'top center',
	borderRadius: '0 0 10px 10px',
	overflow: 'hidden',
	position: 'relative',
});

const verticalCardBackdrop = () => style({ position: 'absolute',
	width: percent(100),
	height: percent(100),
	zIndex: -1,
	filter: 'blur(10px)' });

const verticalCardImageContainer = () => style({
	width: percent(100),
	height: percent(100),
	borderRadius: '0 0 10px 10px',
	zIndex: 1,
	position: 'absolute',
	top: 0,
	left: 0,
	overflow: 'hidden',
});

const verticalCardImage = () => style({
	width: percent(100),
	height: percent(100),
	objectFit: 'scale-down',
	zIndex: 1,
});

const verticalCardTextOuter = () => style({
	width: percent(88),
	display: 'flex',
	margin: '7px auto 10px',
	flexDirection: 'column',
	justifyContent: 'center',
	textAlign: 'left',
});

const verticalCardTitle = () => style(
	fontFredoka,
	{
		margin: '0 0 8px',
		fontSize: px(26),
		fontWeight: 400,
		lineHeight: px(31),
	}
);

const verticalCardDescription = () => style(
	fontRoboto,
	{
		margin: 0,
		fontSize: px(14),
		lineHeight: px(16),
		$nest: dropFirstLastMargin(),
	},
);

export function VerticalCard({ title, image, link, description, class: cls, blur }: CardProps) {
	
	return <div class={cls}>
		<a href={link} class={hrefStyle()}>
			<div class={verticalCardOuter()}>
				<div class={classes(verticalCardContainer(), image ? style({ backgroundColor: '#fff' }) : style({ backgroundColor: '#ccc' }))}>
					<div class={verticalCardImageContainer()}>
						{(blur !== false) && <img class={verticalCardBackdrop()} src={image} />}
						<img class={verticalCardImage()} src={image}/>
					</div>
				</div>
				{(title || description) && <div class={verticalCardTextOuter()}>
					<h2 class={verticalCardTitle()}>
						{title}
					</h2>
					<div class={verticalCardDescription()} dangerouslySetInnerHTML={{ __html: description || '' }} />
				</div>}
			</div>
		</a>
	</div>;
}

const bigCardOuter = () => style(
	shadowRounded,
	media({ minWidth: mediaBreakpointLandscapeOrTablet }, {
		padding: px(40),
	}),
	media({ minWidth: 0, maxWidth: mediaBreakpointLandscapeOrTablet-1 }, {
		padding: px(15),
	}),
	{
		$debugName: 'bigCardOuter',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
	}
);

const bigCardSideContainer = () => style(
	media({ minWidth: mediaBreakpointLandscapeOrTablet }, {
		marginRight: px(40),
		flex: '0 0 170px',
	}),
	media({ minWidth: 0, maxWidth: mediaBreakpointLandscapeOrTablet-1 }, {
		flex: '0 0 100px',
		marginRight: px(20),
	}),
	{
		$debugName: 'bigCardSideContainer',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	}
);

const bigCardImage = () => style(
	media({ minWidth: mediaBreakpointLandscapeOrTablet }, {
		maxWidth: px(150),
	}),
	media({ minWidth: 0, maxWidth: mediaBreakpointLandscapeOrTablet-1 }, {
		maxWidth: px(100),
	}),
	{
		$debugName: 'bigCardImage',
	}
);

const bigCardHref = () => style(
	fontFredoka,
	media({ minWidth: mediaBreakpointLandscapeOrTablet }, {
		fontSize: px(22),
	}),
	media({ minWidth: 0, maxWidth: mediaBreakpointLandscapeOrTablet-1 }, {
		fontSize: px(16),
	}),
	{
		$debugName: 'bigCardHref',
		textAlign: 'center',
	}
);

const bigCardTextOuter = () => style({
	$debugName: 'bigCardTextOuter',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	textAlign: 'start',
	flex: '1 1 auto',
});

const bigCardContent = () => style(
	fontRoboto,
	{
		$debugName: 'bigCardContent',
		fontSize: px(14),
	}
);

export interface BigCardProps {
	image?: string;
	link?: string;
	linkName?: string;
	content?: string;
	class?: string;
}
export function BigCard({ image, link, linkName, content, class: cls }: BigCardProps) {
	
	return <div class={cls}>
		<div class={bigCardOuter()}>
			{(image || link) && <div class={bigCardSideContainer()}>
				{image && <img class={bigCardImage()} src={image}/>}
				{link && <a href={link} class={bigCardHref()}>{linkName ? linkName : 'link'}</a>}
			</div>}
			<div class={bigCardTextOuter()}>
				<div class={bigCardContent()} dangerouslySetInnerHTML={{ __html: content || '' }} />
			</div>
		</div>
	</div>;
}
