import { randomBytes } from 'crypto';

export function camelToKebab(s: string) {
	const fs = (s[0].match(/[A-Z]/) || s.match(/^ms/)) ? '-' + s[0].toLowerCase() + s.substr(1) : s;
	return fs.replace(/[A-Z]/g, c => '-' + c.toLowerCase());
}

export function cut(s: string, n: number) {
	return s.length > n ? s.substr(0, n) + '…' : s;
}

export function nl2br(s: string) {
	return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/\n/g, '<br />');
}

const base36 = Array(36).fill(0).map(
	(_, i) => String.fromCharCode(i >= 10 ? i - 10 + 'a'.charCodeAt(0) : i + '0'.charCodeAt(0)),
);
const getRandomBytes = typeof window !== 'undefined' ? function generateIdImpl(length: number) {
	const arr = new Uint8Array(length);
	crypto.getRandomValues(arr);
	return arr;
} : function generateIdImpl(length: number) {
	return Array.from(randomBytes(length));
};

export function generateId(length = 8) {
	return Array.from(getRandomBytes(length)).map((n: number) => base36[n % 36]).join('');
}

export function replaceMap(str: string, replace: Record<string, string>) {
	return Object.entries(replace).reduce((s, [f, t]) => s.replace(f, t), str);
}
