import { Component, VNode } from 'preact';
import { ContentContainer, ContentItem, ContentItemTypeMap, isContent } from '../../common/data/content.js';
import { Breadcrumb, ContentExtras, ContentWithExtras } from '../../common/data.js';
import { media, style } from 'typestyle';
import { percent, px } from 'csx';
import { NestedCSSProperties } from 'typestyle/lib/types.js';
import { Page } from './page.js';
import { apply } from '../../base/common/scope.js';
import { BigCard, CardProps, HorizontalCard, VerticalCard } from './card.js';
import {
	contentPadding,
	dropFirstLastMargin,
	fontFredoka,
	mediaBreakpointLandscapeOrTablet,
	mediaBreakpointPhone,
	mobileHeaderHeight,
	notchPaddingLeftRight,
	shadowRounded,
} from '../styles.js';
import { findParents } from '../../base/common/tree.js';
import { getThumb } from '../../common/util.js';
import { BreadcrumbBar } from './breadcrumbs.js';

const visible = (children: ContentItem[]) => children.filter(it => it.visible !== false);

const htmlStyles: NestedCSSProperties[] = [
	{
		$nest: {
			'h1, h2, h3': fontFredoka,
			h1: { fontSize: px(48) },
			h2: { fontSize: px(38) },
			h3: { fontSize: px(30) },
			a: { color: 'inherit' },
		},
	},
	media({ maxWidth: px(mediaBreakpointPhone) }, {
		$nest: {
			h1: { fontSize: px(32) },
			h2: { fontSize: px(28) },
			h3: { fontSize: px(24) },
		},
	}),
	media({ maxWidth: px(mediaBreakpointLandscapeOrTablet) }, {
		$nest: {
			h1: { paddingTop: px(0), paddingBottom: px(0) },
			h2: { paddingTop: px(0), paddingBottom: px(0) },
			h3: { paddingTop: px(0), paddingBottom: px(0) },
		},
	}),
];

const itemStyles: Record<string, NestedCSSProperties> = {
	panel: {
		...shadowRounded,
		padding: px(20),
		$nest: dropFirstLastMargin(),
	},
};

const childStyles = (item: ContentItem): NestedCSSProperties => {

	const parent = item.parent;
	const index = parent && parent.children?.indexOf(item);
	
	if (isContent(parent, 'columns')) return apply(
		(item.size || 1) / visible(parent.children).reduce((a, child) => a + (child.size || 1), 0),
		width => ({
			minWidth: px(250),
			flex: `1 0 ${percent(width)}`,
			width: width ? percent(width) : '',
			margin: px(10),
		}),
	);
	
	if (isContent(parent, 'rows')) {
		
		const grandpaRows = findParents<ContentItem>(parent).some(p => p.type == 'rows');
		const first = index === 0;
		const last = index == parent.children!.length - 1;
		
		return {
			marginTop: grandpaRows && first ? 0 : px(20),
			marginBottom: grandpaRows && last ? 0 : px(20),
		};
		
	}
	
	return {};
	
};

const items: {
	[K in keyof ContentItemTypeMap]?: (params: {
		item: ContentItemTypeMap[K];
		extras: ContentExtras;
		parent?: ContentContainer;
		styles?: NestedCSSProperties;
	}) => VNode
} = {

	rows: ({ item, extras, styles }) => <div class={style(styles, { $debugName: 'rows' })}>
		{visible(item.children).map(c => <ContentBlock item={c} extras={extras} parent={item} />)}
	</div>,
	columns: ({ item, extras, styles, parent }) => {
		return <div class={style(styles, {
			$debugName: 'columns',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'stretch',
			margin: isContent(parent, 'rows') ? '10px -10px' : '-10px',
		})}>
			{visible(item.children).map(c => <ContentBlock item={c} extras={extras} parent={item} />)}
		</div>;
	},
	grid: ({ item, extras, styles }) => <div class={style(styles,
		media({ minWidth: 0, maxWidth: mediaBreakpointPhone }, {
			gridTemplateColumns: `repeat(${item.columns >= 3 ? 2 : 1}, 1fr)`,
		}),
		{
			$debugName: 'grid',
			display: 'grid',
			gridTemplateColumns: `repeat(${item.columns || 1}, 1fr)`,
			gridColumnGap: px(20),
			gridRowGap: px(20),
			$nest: { '&>*': { width: percent(100) } },
		})}>
		{visible(item.children).map(c => <ContentBlock item={c} extras={extras} parent={item} />)}
	</div>,

	html: ({ item, styles }) => <div class={style(styles, ...htmlStyles, { $debugName: 'html' })} dangerouslySetInnerHTML={{ __html: item.html }} />,
	image: ({ item, styles }) => <div class={style(styles, { $debugName: 'image' })}>
		{item.src && <img class={style({ maxWidth: percent(100) })} src={item.src} />}
		{item.info && <div>{item.info}</div>}
	</div>,
	
	game: ({ item, extras, parent, styles }) => {
		const game = extras.games[item.game];
		const sss = isContent(parent, 'grid') && parent.columns > 3 ? 250 : 500;
		const props: CardProps = {
			title: game.name,
			description: game.description,
			image: game.screenshot && getThumb(`/data/screenshots/${game.id}/${game.screenshot}`, sss),
			link: `/game/${game.id}`,
			class: style(styles, { $debugName: 'game' }),
		};
		return (
			(isContent(parent, 'grid') && parent.columns > 2)
			|| isContent(parent, 'columns')
		) ? <VerticalCard {...props} /> : <HorizontalCard {...props} />;
	},
	card: ({ item, parent, styles }) => {
		const props: CardProps = {
			...item,
			class: style(styles, { $debugName: 'card' }),
		};
		return (
			(isContent(parent, 'grid') && parent.columns > 2)
			|| isContent(parent, 'columns')
		) ? <VerticalCard {...props} /> : <HorizontalCard {...props} />;
		
	},
	bigCard: ({ item, styles }) => {
		const props: CardProps = {
			...item,
			class: style(styles, { $debugName: 'bigCard' }),
		};
		return (
			<BigCard {...props} />
		);
	},
	
};

export function ContentBlock({ item, parent, extras }: { item: ContentItem; extras: ContentExtras; parent?: ContentContainer }) {
	const styles = {
		...(item.style && itemStyles[item.style]),
		...childStyles(item),
	};
	const renderer = items[item.type];
	if (!renderer) return <div />;
	// @ts-ignore
	return renderer({ item, extras, parent, styles }) || <div />;
}

export interface ContentPageProps extends ContentWithExtras {
	breadcrumbs: Breadcrumb[];
}
export class ContentPage extends Component<ContentPageProps> {

	render({ content, extras, breadcrumbs }: ContentPageProps) {
		
		const rec = (item: ContentItem, parent?: ContentItem) => {
			item.parent = parent;
			item.children?.forEach(child => rec(child, item));
		};
		const root = content.content;
		rec(root);
		
		return <Page class={style(content.id == 'landing' && { paddingTop: px(mobileHeaderHeight) })}>
			{content.url != '/' && <BreadcrumbBar breadcrumbs={breadcrumbs} />}
			<div class={style(
				media({ minWidth: 0, maxWidth: mediaBreakpointLandscapeOrTablet },
					notchPaddingLeftRight(`${contentPadding}px`)),
				{
					$debugName: 'content_page',
					margin: '0 auto',
					maxWidth: px(mediaBreakpointLandscapeOrTablet),
					padding: `0 ${contentPadding}px ${px(32)}`,
				}
			)}>
				<ContentBlock item={root} extras={extras} />
			</div>
		</Page>;
		
	}
	
}
