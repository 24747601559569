import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { get } from 'dtk';

import { ClientInit, Context } from '../common/types.js';

import { initHeader } from './blocks/header.js';
import { initComponent } from './components/init.js';
import { setStylesTarget } from 'typestyle';
import { initFooter } from './blocks/footer.js';
import { createTranslateHandler } from '../base/common/i18n.js';
import { translations } from '../common/i18n.js';

const init = JSON.parse(document.getElementById('data')!.textContent!) as ClientInit;
const context = {
	language: init.language,
	resourceHashes: {},
	config: init.config!,
	translations,
	csrfToken: init.csrfToken,
} as Context;
context.t = createTranslateHandler(translations.en);

(window as any).siteContext = context;

if (init.menuCache?.menus) init.menuCache.svgs = Object.fromEntries(
	([...document.querySelectorAll('.menu-svg-container')] as HTMLElement[]).map(el => [el.dataset.svg, el.innerHTML])
);

const header = get('#header');
if (header) initHeader(header, init);

if (init.component) initComponent(init);

const footer = get('#footer');
if (footer) initFooter(footer, init);

setStylesTarget(document.getElementById('typestyle')!);
