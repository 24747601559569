import { CommonConfigBase } from '../../base/common/types.js';
import { brand } from '../values.js';

function numberForm(n: number) {
	return n == 1 ? 0 : 1;
}

export const en = {

	numberForm,

	toIndex: 'Return',

	yourEmail: 'Email address',

	restoreEmailSubject: () => `Your ${brand} password restore link`,
	restoreEmailText: ({ token, config }: { config: CommonConfigBase; token: string }) => `Hi,

Someone requested a link to restore password for ${brand} account with this email address.
If it wasn't you, you can probably ignore this email. Otherwise, here it is:

${config.protocol}://${config.domain}/email-token/${token}

This link will expire in 24 hours.

See you there,
${brand}
`,

};
