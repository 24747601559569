import { Component } from 'preact';
import { get } from 'dtk';
import { digestPassword } from '../lib/util.js';
import { updateUser } from '../lib/api.js';
import { Page } from './page.js';

export interface SettingsEditorProps {
	email?: string;
}
export interface SettingsEditorState {
	email?: string;
	password?: string;
	error?: string;
	success?: string;
}

export class SettingsEditor extends Component<SettingsEditorProps, SettingsEditorState> {

	render(props: SettingsEditorProps, state: SettingsEditorState) {
		return <Page class="settings">
			<h2>Settings</h2>
			<form className="form" onSubmit={e => { e.preventDefault(); this.submit(); }}>
				<label>
					<span>Email address:</span>
					<input type="text" name="email" placeholder="you@example.org" autocomplete="email" value={state.email || props.email} />
				</label>
				<label>
					<span>New password:</span>
					<input type="password" name="password" placeholder="password" autocomplete="off" value={state.password} />
				</label>
				<label>
					<span>Confirm password:</span>
					<input type="password" name="confirm" placeholder="confirm" autocomplete="off" />
				</label>
				{state.error && <div className="form__error">{state.error}</div>}
				{state.success && <div className="form__success">{state.success}</div>}
				<button type="submit">Update</button>
			</form>
		</Page>;
	}
	
	async submit() {

		const form = this.base as HTMLElement;

		const email = get(form, 'input', '[name=email]')!.value.trim();
		const pwtext = get(form, 'input', '[name=password]')!.value.trim() || undefined;
		const confirm = get(form, 'input', '[name=confirm]')!.value.trim() || undefined;

		this.setState({
			error: undefined,
			success: undefined,
			email,
			password: pwtext,
		});
		
		if (!email) return this.setState({ error: 'Please fill in the email' });
		if (pwtext && pwtext != confirm) return this.setState({ error: 'Passwords do not match' });

		const password = pwtext && await digestPassword(pwtext);

		const res = await updateUser({ email, password });

		if (res.error || !res.success) this.setState({ error: res.error || 'Unknown error' });

		if (res.success) this.setState({ success: 'Settings updated', password: undefined });
		
	}

}
