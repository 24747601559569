export type TranslationMapBase = {};

export type TranslateHandler<M> = <
	Id extends keyof M,
	Val extends M[Id],
	Args extends (Val extends ((...args: any) => any) ? Parameters<Val> : []),
>(
	id: Id,
	...args: Args
) => (
	Val extends (...args: any) => any ? ReturnType<Val> : Val
);

export const createTranslateHandler = <M>(map: M) => <Id extends keyof M>(id: Id, ...args: any[]) => {
	const val = map[id] || id;
	if (typeof val != 'function') return val;
	return (val as any)(...args);
};

export type AllTranslationMap<TM> = Record<string, { [key in keyof TM]: TM[key] }>;
