import { NestedCSSProperties, NestedCSSSelectors } from 'typestyle/lib/types.js';
import { px } from 'csx';

export const mediaBreakpointLandscapeOrTablet = 896;
export const mediaBreakpointLandscapeOrTabletHeight = 600;
export const mediaBreakpointPhone = 500;

export const mobileHeaderHeight = 50;

export const backgroundColor = '#408fc0';
export const mobileHeaderColor = '#0366B4';

export const contentPadding = 12;

export const centered: NestedCSSProperties = {
	width: px(mediaBreakpointLandscapeOrTablet),
	margin: 'auto',
};

export const shadow: NestedCSSProperties = {
	filter: 'drop-shadow(10px 10px 0px rgba(0,0,0,0.45))',
	transform: 'translateZ(0)',
};

export const textColorDark: NestedCSSProperties = {
	color: '#408FC0',
};

export const shadowRounded: NestedCSSProperties = {
	...shadow,
	...textColorDark,
	borderRadius: px(8),
	backgroundColor: 'white',
};

export const fontFredoka: NestedCSSProperties = {
	fontFamily: '\'Fredoka One\', sans-serif',
	fontWeight: 400,
};

export const fontRoboto: NestedCSSProperties = {
	fontFamily: '\'Roboto\', sans-serif',
};

export const textShadow = (size = px(7)) => ({
	filter: 'drop-shadow(rgba(0,0,0,0.45) ' + size + ' ' + size +')',
} as NestedCSSProperties);

// Note for notchPaddingLeftRight() & notchMarginLeftRight()
// if you don't need any padding if there's no notch:
//
// max() will always return 0 if you give unitless "0" instead of "0px"
// https://stackoverflow.com/questions/62520998/why-doesnt-min-or-max-work-with-unitless-0

export function notchPaddingLeftRight(padding: string): NestedCSSProperties {
	return {
		paddingLeft: 'max(env(safe-area-inset-left),' + padding + ')',
		paddingRight: 'max(env(safe-area-inset-right),' + padding + ')',
	};
}

export function notchMarginLeftRight(margin: string): NestedCSSProperties {
	return {
		marginLeft: 'max(env(safe-area-inset-left),' + margin + ')',
		marginRight: 'max(env(safe-area-inset-right),' + margin + ')',
	};
}

export const dropFirstLastMargin = (selector = '*'): NestedCSSSelectors => ({
	[`${selector}:first-child`]: { marginTop: 0 },
	[`${selector}:last-child`]: { marginBottom: 0 },
});

export const button: NestedCSSProperties = {
	...shadowRounded,
	...fontFredoka,
	border: 0,
	padding: px(10),
	fontSize: px(20),
	$nest: {
		'&:active': {
			filter: 'none',
			transform: 'translateZ(0) translate(10px, 10px)',
		},
	},
};
