import { Component } from 'preact';
import { Content } from '../../common/data.js';
import { percent, viewHeight } from 'csx';
import { Page } from './page.js';
import { style } from 'typestyle';
import { apply } from '../../base/common/scope.js';

export interface ContentComparerProps {
	content: Content;
}

interface ContentComparerState {
	left: string;
	right: string;
}

export class ContentComparer extends Component<ContentComparerProps, ContentComparerState> {
	
	iframes: Record<string, HTMLIFrameElement | null> = {};
	
	constructor(props: ContentComparerProps) {
		super(props);
		this.state = {
			left: props.content.url,
			right: `/old${props.content.old_url}`,
		};
	}
	
	render({ content }: ContentComparerProps, state: ContentComparerState) {
		return <Page class={style({ display: 'flex', width: percent(100), height: viewHeight(100), alignItems: 'stretch' })}>
			{(['left', 'right'] as const).map((key, i) => apply(state[key], url => <div class={style({ flex: '1 1 50%', display: 'flex', flexDirection: 'column' })}>
				<a href={url} target="_blank">{url}</a>
				<iframe src={url} class={style({ flex: '1 1 100%', background: 'white' })} ref={it => this.iframes[key] = it} />
			</div>))}
		</Page>;
	}
	
}
