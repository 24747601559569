import { generateId } from '../../base/common/string.js';

export interface ContentItem {

	id: string;
	type: keyof ContentItemTypeMap;
	size?: number;
	style?: string;
	children?: ContentItem[];
	visible?: boolean;
	
	parent?: ContentItem; // add when displaying only
	
}

export interface ContentContainer extends ContentItem {
	type: 'rows' | 'columns' | 'grid';
	children: ContentItem[];
}

export interface ContentHTML extends ContentItem {
	type: 'html';
	html: string;
}

export interface ContentImage extends ContentItem {
	type: 'image';
	src?: string;
	info?: string;
}

export interface ContentGame extends ContentItem {
	type: 'game';
	game: string;
}

export interface ContentGrid extends ContentContainer {
	type: 'grid';
	columns: number;
}

export interface ContentCard extends ContentItem {
	type: 'card';
	title?: string;
	link?: string;
	image?: string;
	blur?: boolean;
	description?: string;
}

export interface ContentBigCard extends ContentItem {
	type: 'bigCard';
	image?: string;
	content?: string;
	link?: string;
	linkName?: string;
}

export interface ContentItemTypeMap {

	init: ContentItem;
	
	rows: ContentContainer;
	columns: ContentContainer;
	html: ContentHTML;
	image: ContentImage;
	grid: ContentGrid;
	
	game: ContentGame;
	card: ContentCard;
	bigCard: ContentBigCard;
	
}

export type MakeContentProps<T> = Omit<T, 'id' | 'type'> & { id?: string };

export const makeContent = <
	K extends keyof ContentItemTypeMap,
	T extends ContentItemTypeMap[K]
> (type: K, props: MakeContentProps<T>) => ({
		id: props.id || generateId(),
		type: type as T['type'],
		...props,
	} as T);

export const isContent = <
	T extends ContentItemTypeMap[K],
	K extends keyof ContentItemTypeMap = 'init',
> (item: any, type?: K): item is (T & { type: K }) => (
		item && typeof item == 'object' && item.id && (!type || item.type == type)
	);

export const isContentContainer = (item: any): item is ContentContainer => (
	item && typeof item == 'object' && Array.isArray(item.children)
);

export const contentStyles = ['panel', 'header'];
