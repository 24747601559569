import { Component } from 'preact';
import { Breadcrumb, Game, User } from '../../common/data.js';
import { media, style } from 'typestyle';
import { em, percent, px } from 'csx';
import { Page } from './page.js';
import {
	backgroundColor,
	contentPadding,
	fontFredoka,
	fontRoboto,
	mediaBreakpointLandscapeOrTablet,
	mediaBreakpointLandscapeOrTabletHeight,
	notchPaddingLeftRight,
	shadowRounded,
} from '../styles.js';
import { Share, ShareProps } from './share.js';
import { NestedCSSProperties } from 'typestyle/lib/types.js';
import { BreadcrumbBar } from './breadcrumbs.js';
import { EnterFullscreenIcon } from './icons.js';

export interface GamePageProps {
	user?: User;
	game: Game;
	localPath?: string;
	ruffleVersion?: string | number;
	breadcrumbs: Breadcrumb[];
}
interface GamePageState {
	testStatus?: boolean | null;
	testComment?: string;
	testStatusSaved?: boolean;
}

export class GamePage extends Component<GamePageProps, GamePageState> {
	
	frame: HTMLIFrameElement | null = null;
	
	constructor(props: GamePageProps) {
		super(props);
		this.state = {
			testStatus: props.game.test_status,
			testComment: props.game.test_comment,
		};
		
	}

	render(props: GamePageProps, state: GamePageState) {
	
		const ratio = this.props.game.aspect_ratio || 1.333;
		const landscape = ratio > 1;
		
		const gameDimensions = landscape ? [
			{
				'--game-width': `calc((87vh - 48px) * ${ratio})`,
				'--game-height': `calc(87vh - 48px)`,
			} as NestedCSSProperties,
			media({ orientation: 'portrait', maxWidth: mediaBreakpointLandscapeOrTablet-1 }, {
				'--game-width': 'calc(90vw - 8px)',
				'--game-height': `calc((90vw - 8px) / ${ratio})`,
			} as NestedCSSProperties),
			media({ orientation: 'landscape', maxWidth: mediaBreakpointLandscapeOrTablet-1 }, {
				'--game-width': `calc((87vh - 48px) * ${ratio})`,
				'--game-height': `calc(87vh - 48px)`,
			} as NestedCSSProperties),
			media({ minWidth: mediaBreakpointLandscapeOrTablet, minHeight: mediaBreakpointLandscapeOrTabletHeight }, {
				'--game-width': px(mediaBreakpointLandscapeOrTablet-40),
				'--game-height': px((mediaBreakpointLandscapeOrTablet-40) / (ratio)),
			} as NestedCSSProperties),
		] : [
			{
				'--game-width': `calc((87vh - 48px) * ${ratio})`,
				'--game-height': `calc(87vh - 48px)`,
			} as NestedCSSProperties,
			media({ orientation: 'portrait', maxWidth: mediaBreakpointLandscapeOrTablet-1 }, {
				'--game-width': 'calc(90vw - 8px)',
				'--game-height': `calc((90vw - 8px) / ${ratio})`,
			} as NestedCSSProperties),
			media({ orientation: 'landscape', maxWidth: mediaBreakpointLandscapeOrTablet-1 }, {
				'--game-width': `calc((87vh - 48px) * ${ratio})`,
				'--game-height': `calc(87vh - 48px)`,
			} as NestedCSSProperties),
			media({ minWidth: mediaBreakpointLandscapeOrTablet, minHeight: mediaBreakpointLandscapeOrTabletHeight }, {
				'--game-width': `calc((100vh - 300px) * ${ratio})`,
				'--game-height': `calc(100vh - 300px)`,
			} as NestedCSSProperties),
		];
		
		const share = style(
			media({ maxWidth: mediaBreakpointLandscapeOrTablet-1 }, { order: 10, alignSelf: 'flex-end', marginLeft: '0' }),
			{
				$debugName: 'game_desc_share',
				flex: '0 0 auto',
				marginLeft: px(36),
			}
		);
		
		const shareProps: ShareProps = {
			pathname: '/game/' + props.game.id,
			shareStyle: share,
		};
		
		return <Page>
		
			<BreadcrumbBar breadcrumbs={props.breadcrumbs} gamePage={true} />
			
			<div class={style(
				notchPaddingLeftRight(`${contentPadding}px`),
				{
					$debugName: 'game_page',
					maxWidth: px(mediaBreakpointLandscapeOrTablet),
					margin: '0 auto',
				},
				...gameDimensions
			)}>
				
				<div class={style(
					shadowRounded,
					{
						$debugName: 'game_iframe_container',
						padding: px(10),
						display: 'flex',
						justifyContent: 'center',
						width: 'fit-content',
						margin: '20px auto 40px',
						userSelect: 'none',
						position: 'relative',
					}
				)}>
					<div
						class={style({
							$debugName: 'game_iframe_box',
							width: 'var(--game-width)',
							height: 'var(--game-height)',
							background: 'white',
							position: 'relative',
						})}
					>
						<iframe
							ref={it => this.frame = it}
							src={`/game-frame/${props.game.id}${props.game.path}.html`}
							scrolling="no"
							class={style({
								position: 'fixed',
								$debugName: 'game_iframe',
								width: 'var(--game-width)',
								height: 'var(--game-height)',
								overflow: 'hidden',
								// pointerEvents: 'none',
								zIndex: 20,
							})}
						/>
					</div>
					<button
						className={style({
							border: '0',
							borderBottomLeftRadius: px(8),
							borderBottomRightRadius: px(8),
							backgroundColor: 'white',
							color: backgroundColor,
							cursor: 'pointer',
							position: 'absolute',
							bottom: px(-28),
							right: 0,
						})}
						onClick={this.enterFullScreen}
					>
						<EnterFullscreenIcon class={style({ height: px(24), color: '#408FC0', margin: px(4) })} />
					</button>
				</div>
				
				{!landscape ? {} : <div class={style(
					media({ orientation: 'portrait', maxWidth: mediaBreakpointLandscapeOrTablet }, notchPaddingLeftRight('10px'), { display: 'block' }),
					fontFredoka,
					{
						$debugName: 'turn_phone',
						display: 'none',
						paddingTop: px(20),
						backgroundColor: 'rgba(255,255,255,0.3)',
						fontSize: px(25),
						textAlign: 'center',
						borderRadius: px(8),
						margin: '20px auto',
					}
				)}>
					<img src="/static/rotate_phone.svg" class={style({
						height: `calc(100vh - var(--game-height) - 386px)`,
						display: 'block',
						margin: '0 auto',
						minHeight: px(100),
					})}/>
					Turn your phone for<br/>better experience
				</div>}
	
				<div class={style(
					media({ maxWidth: mediaBreakpointLandscapeOrTablet-1 }, notchPaddingLeftRight('0px'), { margin: '24px 15px', flexDirection: 'column' }),
					{
						$debugName: 'game_desc_outer',
						display: 'flex',
						alignItems: 'baseline',
						flexWrap: 'wrap',
						marginTop: px(24),
					}
				)}>
				
					<div class={style(
						fontFredoka,
						media({ minWidth: 840 }, { flex: '1' }),
						{
							$debugName: 'game_desc_name',
							fontSize: px(48),
							lineHeight: 1,
						}
					)}>
						{props.game.name}
					</div>
					
					<Share {...shareProps}/>
					
					<div class={style(fontRoboto,
						media({ maxWidth: mediaBreakpointLandscapeOrTablet-1 }, { width: 'inherit' }),
						{
							$debugName: 'game_desc_body',
							flex: '1 1 100%',
							fontSize: px(18),
							width: percent(60),
							fontWeight: 700,
							lineHeight: 1.3,
							marginTop: em(1),
							marginBottom: em(1.5),
						})} dangerouslySetInnerHTML={{ __html: props.game.description || '' }} />
				</div>
				
			</div>
			
		</Page>;
	}
	
	enterFullScreen = () => {
		this.frame?.contentWindow?.postMessage('enterFullscreen');
	}
	
	componentDidMount() {
		window.screen?.orientation?.addEventListener('change', e => {
			if (window.screen.orientation.type.match(/^landscape-/)) this.enterFullScreen();
		});
	}
	
}

