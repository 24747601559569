export function sleep(ms: number) {
	return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export function throttle<A extends any[]>(fun: (...args: A) => void, time = 100, eager = false) {
	let curArgs: A;
	let timeout: any;
	let last = 0;
	const call = function () {
		timeout = undefined;
		last = Date.now();
		fun(...curArgs!);
	};
	return function (...args: A) {
		curArgs = args;
		if (timeout) return;
		if (eager && Date.now() - last >= time) call();
		else timeout = setTimeout(call, time);
	};
}

export function debounce<A extends any[]>(fun: (...args: A) => void, time = 100) {
	let curArgs: A;
	let timeout: any;
	const call = function () {
		timeout = undefined;
		fun(...curArgs!);
	};
	return function (...args: A) {
		curArgs = args;
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(call, time);
	};
}

type QueueEntry<R, A extends any[]> = {
	promise: Promise<R>;
	args: A;
	resolve: (value: R | PromiseLike<R>) => void;
	reject: (reason?: any) => void;
};
export function queue<R, A extends any[]>(fun: (...args: A) => R, until = (res: R) => res === queue.done) {
	
	let q: QueueEntry<R, A>[] | null = null;
	let done = false;
	let result: R | undefined;
	
	function run(qe: QueueEntry<R, A>) {
		if (done) return qe.resolve(result!);
		try {
			qe.resolve(fun(...qe.args));
		}
		catch (e) {
			qe.reject(e);
		}
	}
	
	function next() {
		if (q && q.length) {
			const qe = q.shift()!;
			qe.promise.then(r => {
				if (!done && until(r)) {
					done = true;
					result = r;
				}
				next();
			}, e => {
				console.error(e);
				next();
			});
			run(qe);
		}
		else {
			q = null;
		}
	}
	
	return (...args: A) => {
		
		const start = !q;
		if (!q) q = [];
		
		const qe: Partial<QueueEntry<R, A>> = { args };
		const p = qe.promise = new Promise<R>((resolve, reject) => {
			qe.resolve = resolve;
			qe.reject = reject;
		});
		q.push(qe as QueueEntry<R, A>);
		
		if (start) next();
		
		return p;
		
	};
	
}
queue.done = {};
