import { media, style } from 'typestyle';
import { px } from 'csx';
import { Breadcrumb } from '../../common/data.js';
import { centered, contentPadding, mediaBreakpointLandscapeOrTablet, mobileHeaderHeight } from '../styles.js';

export function BreadcrumbBar({ breadcrumbs, gamePage }: { breadcrumbs: Breadcrumb[]; gamePage?: boolean }) {
	const last = breadcrumbs[breadcrumbs.length - 1];
	return <div class={style({
		$debugName: 'breadcrumbs',
		fontFamily: 'Fredoka One, sans-serif',
		fontSize: px(18),
	})}>
		<div class={style(
			{
				$debugName: 'breadcrumbs_desktop',
				display: 'none',
				flexWrap: 'wrap',
				...centered,
				padding: `0 ${contentPadding}px`,
			},
			media({ minWidth: mediaBreakpointLandscapeOrTablet }, { display: 'flex' }),
		)}>
			{breadcrumbs.map((crumb, i) => <div class={style({
				$debugName: 'breadcrumbs_item',
				display: 'flex',
			})}>
				<a href={crumb.url} class={style({
					textDecoration: 'none',
				})}>{crumb.title || crumb.id}</a>
				<div class={style({
					$debugName: 'breadcrumbs_slash',
					padding: '0 10px',
				})}>{' / '}</div>
			</div>)}
		</div>
		<div class={style(
			{
				$debugName: 'breadcrumbs_mobile',
				display: 'none',
				flexWrap: 'wrap',
			},
			!gamePage && {
				padding: `${mobileHeaderHeight}px 4px 0`,
				borderBottom: '2px solid white',
			},
			gamePage && {
				padding: `5px 50px 0 35px`,
				textAlign: 'center',
				justifyContent: 'center',
			},
			media({ maxWidth: mediaBreakpointLandscapeOrTablet - 1 }, { display: 'flex' }),
		)}>
			<a href={last.url} class={style({ textDecoration: 'none' })}>
				🡐 {last.title || last.id}
			</a>
		</div>
	</div>;
}
