import { Logo } from './icons.js';
import { media, style } from 'typestyle';
import { px } from 'csx';
import { apply, maybe } from '../../base/common/scope.js';
import { getThumb } from '../../common/util.js';
import { MenuCache } from '../types.js';
import { mediaBreakpointPhone, notchPaddingLeftRight } from '../styles.js';
import { ContentWithExtras } from '../../common/data.js';
import { ContentBlock } from './content.js';

const linkStyle = style(
	{
		$debugName: 'linkStyle',
		lineHeight: px(30),
		fontSize: px(18),
		textDecoration: 'none',
		$nest: {
			'&:hover': { opacity: .5 },
		},
	}
);

const navHeadingStyle = style(
	{
		$debugName: 'navHeadingStyle',
		fontSize: px(20),
		lineHeight: px(30),
		fontWeight: 'bold',
		margin: '0 0 15px',
	}
);

export function Footer({ menuCache, content }: { menuCache: MenuCache; content?: ContentWithExtras }) {

	return (
		<footer id="footer" class={style(
			{
				$debugName: 'footer',
				maxWidth: px(840),
				margin: 'auto',
				borderTop: '2px solid rgba(0, 0, 0, .2)',
			}
		)}>
			<div class={style(
				notchPaddingLeftRight('20px'),
				{
					$debugName: 'footer_inner',
					margin: '30px 0 30px',
					display: 'flex',
					justifyContent: 'space-between',
				},
				// media({ maxWidth: mediaBreakpointLandscapeOrTablet }, { margin: "30px 20px 30px" }),
				media({ maxWidth: mediaBreakpointPhone }, { flexDirection: 'column-reverse' })
			)}>
				<div class={style(
					{
						$debugName: 'footer_more_inner',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						paddingRight: px(10),
					}
				)}>
					<a href="/" class={style(
						{
							$debugName: 'footer_logo',
							width: px(80),
							paddingBottom: px(15),
						}
					)}>
						<Logo />
					</a>
					<div>
						{content && <div class={style({
							$debugName: 'footer_content',
							fontSize: px(14),
							$nest: {
								p: { margin: 0 },
							},
						})}>
							<ContentBlock item={content.content.content} extras={content.extras} />
						</div>}
						<p>
							<span class={style(
								{
									fontSize: px(13),
									opacity: .7,
								}
							)}>
								Copyright &#169; {new Date().getFullYear()}
							</span>
						</p>
					</div>
				</div>
				<div class={style(
					{
						display: 'flex',
					}
				)}>
					{menuCache!.menus.footer.entries.map((me, i) => apply(me.submenu && menuCache!.menus[me.submenu] || undefined, submenu => <nav class={style({ paddingLeft: i && px(20) })}>
						<h3 className={navHeadingStyle}>{me.title}</h3>
						<ul>
							{submenu?.entries.map(sme => <li class={style({
								$nest: Object.fromEntries(['svg', 'img'].map(tag => [tag, {
									width: px(19),
									marginRight: px(5),
								}])),
							})}>
								{sme.image && (
									maybe(menuCache!.svgs[sme.image], svg => <span class="menu-svg-container" data-svg={sme.image} dangerouslySetInnerHTML={{ __html: svg }} />)
									|| <img src={getThumb(`/data/menus/${me.image}`, 250, 'png')} />
								)}
								<a className={linkStyle} target="_blank" href={sme.link}>{sme.title}</a>
							</li>)}
						</ul>
					</nav>))}
				</div>
			</div>
		</footer>
	);
}
