interface FileTuple {
	path: (string | number)[];
	blob: Blob;
}

function extractBlobs(obj: any, acc: FileTuple[] = [], path: (string | number)[] = []) {
	
	if (!obj) return acc;
	
	if (Array.isArray(obj)) {
		obj.forEach((item, i) => {
			const np = path.concat(i);
			if (item instanceof Blob) {
				acc.push({ path: np, blob: item });
				obj[i] = void 0;
			}
			else {
				extractBlobs(item, acc, np);
			}
		});
	}
	else if (typeof obj == 'object') {
		for (const key in obj) {
			const np = path.concat(key);
			if (obj[key] instanceof Blob) {
				acc.push({ path: np, blob: obj[key] });
				obj[key] = void 0;
			}
			else {
				extractBlobs(obj[key], acc, np);
			}
		}
	}
	
	return acc;
	
}

function post(url: string, params?: Record<string, any>) {
	return new Promise(resolve => {
		
		const xhr = new XMLHttpRequest();
		xhr.open('POST', url);
		xhr.setRequestHeader('X-CSRF-TOKEN', (window as any).siteContext.csrfToken);
		
		const blobs = extractBlobs(params);
		if (blobs && blobs.length) {
			const fd = new FormData();
			fd.append('json', JSON.stringify(params));
			for (const bd of blobs) fd.append(btoa(JSON.stringify(bd.path)), bd.blob);
			xhr.send(fd);
		}
		else {
			xhr.send(params && JSON.stringify(params));
		}
		
		xhr.onerror = e => resolve({ error: 'Connection error' });
		xhr.onload = () => {
			try {
				
				const res = JSON.parse(xhr.responseText);
				
				if (res.error) resolve(res);
				else resolve(res.result);
			}
			catch (e) {
				resolve({ error: 'Server error' });
			}
		};
		
	});
}

export function createWebAPIClient(base = '/api/') {
	return (method: string, params?: Record<string, any>) => post(base + method, params);
}
