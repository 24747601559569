import { h, render } from 'preact';
import { Header } from '../components/header.js';
import { ClientInit } from '../../common/types.js';

export function initHeader(header: HTMLElement, init: ClientInit) {
	render(h(Header, {
		menuCache: init.menuCache,
		languages: init.languages,
		gamePage: init.component == 'GamePage',
	}), header.parentElement!, header);
}

